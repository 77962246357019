<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <v-container>
        <v-row class="align-center d-flex h-0 pb-20">
            <v-col lg="3" sm="8" class="mx-auto">
               
                <checkmong-home-bar title=""></checkmong-home-bar>
            </v-col>
        </v-row>
        <v-row class="align-center d-flex h-0 pb-20">
            
            <v-col lg="3" sm="8" class="mx-auto" style="text-align: center;">
              
                <br />

                <span class="text-purple">คิวอาร์โค้ดของฉัน</span>
                <!-- <button id="start" @click="clearTimer"><i class="material-icons" >play_circle_filled</i></button> -->
                {{time_test}}
                <br />
                <br />

                <v-row class="">
                    <v-col cols="12" style="text-align: center;">

                        <qrcode-vue :value="token" :size="size" level="H" class="qr-code" />

                    </v-col>
                </v-row>
                <v-row class="d-flex">
                    <v-col cols="7" style="text-align: end; padding-right: 0px;">
                        <v-icon small color="#1F98BD">timer</v-icon>
                    </v-col>
                    <v-col cols="2" style="text-align: center;padding-left: 0px;padding-right: 0px;">
                        <span>{{ minute_count }}:{{second_count}}</span>
                    </v-col>
                        <v-col cols="3" style="text-align: left; padding-left: 0px;">
                        <span>นาที</span>
                    </v-col>

                </v-row>
                <v-row class="d-flex">
                    <v-col cols="12" style="text-align: center; ">
                        <v-chip @click="$router.go(0)">
                            <v-icon small color="purple">refresh</v-icon><span style="color:purple;">&nbsp;โหลดใหม่</span>
                        </v-chip>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center " style="padding-top: 15px">
            <v-col lg="3" sm="8" class="mx-auto" style="text-align: left;">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
            </v-col>
    
        </v-row>
    
        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-left">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl ml-6">
                    <v-icon small>west</v-icon>
                </v-btn>
            </v-row>
        </v-footer>
    </v-container>
</div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";

export default {
    name: "calendar-patient",
    components: {
        QrcodeVue,
        WaitingModal,
        CheckmongHomeBar
    },
    data: () => ({
        time_test : '',
        t : 100000,
        timerCount: 0,
        minute_count: 0,
        second_count: 0,
        title: '',
        active_waiting: false,
        status: '',
        alert_name: '',
        token: '',
        size: 200,

        attrs: "",
        on: "",
        selected_row: "",
        dialog_edit: false,
        dialog_new: false,
        arrayEvents: null,
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),

    }),

    mounted() {
        this.arrayEvents = [...Array(6)].map(() => {
            const day = Math.floor(Math.random() * 30);
            const d = new Date();
            d.setDate(day);
            return d.toISOString().substr(0, 10);
        });
        this.generate_qr()
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--
                        var sec_num = this.timerCount

                        var minutes = Math.floor((sec_num) / 60);
                        var seconds = sec_num - (minutes * 60);
                        if (minutes < 10) {
                            minutes = "0" + minutes;
                        }
                        if (seconds < 10) {
                            seconds = "0" + seconds;
                        }

                        this.minute_count = minutes
                        this.second_count = seconds
                    }, 1000);
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        },
    },
    methods: {

        handleClick(value) {
            this.selected_row = value;
            this.dialog_edit = true;
        },
        edit_data(alert) {
            this.edit_aleart = alert;
            this.dialog_edit = true;
        },
        generate_qr() {
            this.token = String("www.checkmong.com/") + this.$store.state.login_detail.user_id.substring(1) + Math.random().toString(36).slice(2);
            this.submitVal()
        },
        update_status_wait(val) {
            this.active_waiting = val
        },
        clearTimer(){
            clearInterval(this.timeCount)
        },
        async submitVal() {

            this.active_waiting = true
            this.title = 'กำลังสร้าง QR ใหม่',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/add_patient_qr', {
                "user_patient_id": this.$store.state.login_detail.user_id,
                "current_qr": this.token.split("/")[1].substring(6)
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data.detail == 'qr_added') {
                    this.title = '';
                    this.status = 'done'
                    this.active_waiting = false
                    this.timerCount = 600
                    
                    // setTimeout(() => {
                    //     this.active_waiting = false
                    // }, 2000)
                } else {
                    this.active_waiting = false
                    this.title = 'ระบบขัดข้อง',
                        this.status = 'fail'
                }

            })

        },
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0px;
}

.qr-code {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    padding-top: 12px;
    width: 225px;
    display: inline-block;
}
</style>
